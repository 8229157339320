<template>
  <div class="Details">
    <img
      v-if="Details.thumb != ''"
      class="thumb"
      :src="'https://api.lphadmin.cn/uploads/' + Details.thumb"
      alt=""
    />
    <div class="Box">
      <div class="title">
        {{ Details.articleTitle }}
      </div>
      <div class="message">
        <p>小情绪</p>
        <p><i class="iconfont">&#xe6a7;</i> {{ Details.articleClassify }}</p>
        <p><i class="iconfont">&#xe600;</i> {{ Details.creationTime }}</p>
        <p><i class="iconfont">&#xe661;</i> {{ Details.articleHit }}</p>
        <!-- <p><i class="iconfont">&#xe8ab;</i> {{ Details.GiveLike }}</p> -->
      </div>
      <div class="articleLable">
        <RouterLink
          to=""
          v-for="(item, index) in Details.articleLable"
          :key="index"
        >
          <span v-for="(i, index) in labelList" :key="index">
            <el-tag
              v-if="item === i.labelId"
              :style="{
                color: i.labelColor,
                backgroundColor: i.labelBgc,
                borderColor: i.labelBgc,
              }"
              >{{ i.labelName }}</el-tag
            >
          </span>
        </RouterLink>
      </div>
      <div class="articleDigest">
        {{ Details.articleDigest }}
      </div>
      <div class="articleContent">
        <v-md-preview
          :text="Details.articleContent"
          @copy-code-success="handleCopyCodeSuccess"
        ></v-md-preview>
        <div class="soulChicken">
          {{ SoulChicken }}
          <span class="goodbye">再见！</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { getDetails, hit, random } from "@/api/article";
import { getLabel } from "@/api/label";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
const store = useStore();
const router = useRouter();
onMounted(() => {
  // ArticleDetails(store.state.article.id);
  ArticleDetails(router.currentRoute.value.query.id);
  getLabels();
  getRandom(); // 获取鸡汤
  console.log(store.state.article);
  document.title = store.state.article.title + " - 小情绪";
});
// 文章详情
let Details = ref({});
const ArticleDetails = (articleId) => {
  let obj = {
    articleId,
  };
  getDetails(obj).then((res) => {
    console.log(res);
    if (res.code === 200) {
      Details.value = res.data.data[0];
      Details.value.articleLable = JSON.parse(Details.value.articleLable);
      getHit(Details.value.articleId);
    }
  });
};

// 点击数
const getHit = (articleId) => {
  let obj = {
    articleId,
  };
  hit(obj).then((res) => {
    console.log(res.msg);
  });
};

// 获取标签
const labelList = ref([]);
const getLabels = () => {
  getLabel().then((res) => {
    console.log(res);
    if (res.code === 200) {
      labelList.value = res.data.data;
    }
  });
};

// 复制按钮
const handleCopyCodeSuccess = (code) => {
  console.log(code);
};

const SoulChicken = ref("");
// 随机获取鸡汤
const getRandom = () => {
  random().then((res) => {
    SoulChicken.value = res.data.chicken;
  });
};
</script>

<style scoped lang="scss">
.Details {
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  .Box {
    padding: 20px;
  }

  .thumb {
    width: 100%;
    height: 320px;
    border-radius: 4px;
  }

  .title {
    font-size: 24px;
    font-weight: 700;
  }

  .message {
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    margin-top: 20px;

    p {
      padding-right: 10px;
    }
  }

  .articleLable {
    display: flex;
    margin-top: 20px;
    height: 20px;

    a {
      margin-right: 10px;
      display: flex;
      text-decoration: none;
    }
  }

  .articleDigest {
    background-color: #f6f6f6;
    border: 1px solid #f3f3f3;
    padding: 10px 20px;
    box-sizing: border-box;
    font-size: 16px;
    color: #888;
    margin-top: 30px;
  }

  .articleContent {
    margin-top: 20px;
    .vuepress-markdown-body {
      padding: 0;
    }
    .soulChicken {
      padding: 10px 0;
      border-top: 1px solid #eaecef;
      .goodbye {
        display: block;
        margin-top: 15px;
        text-align: right;
      }
    }
  }
}

@media screen and (max-width: 996px) {
  .Details {
    .Box {
      padding: 0;
    }

    .vuepress-markdown-body div[class*="v-md-pre-wrapper-"] {
      margin: 0;
    }
  }

  .Details .thumb {
    height: 300px;
  }
}

@media screen and (max-width: 540px) {
  .Details .thumb {
    height: 230px;
  }
}

@media screen and (max-width: 414px) {
  .Details .thumb {
    height: 180px;
  }
}
</style>
